exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aktuelles-js": () => import("./../../../src/pages/aktuelles.js" /* webpackChunkName: "component---src-pages-aktuelles-js" */),
  "component---src-pages-archiv-js": () => import("./../../../src/pages/archiv.js" /* webpackChunkName: "component---src-pages-archiv-js" */),
  "component---src-pages-biographie-js": () => import("./../../../src/pages/biographie.js" /* webpackChunkName: "component---src-pages-biographie-js" */),
  "component---src-pages-en-aktuelles-js": () => import("./../../../src/pages/en/aktuelles.js" /* webpackChunkName: "component---src-pages-en-aktuelles-js" */),
  "component---src-pages-en-archiv-js": () => import("./../../../src/pages/en/archiv.js" /* webpackChunkName: "component---src-pages-en-archiv-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalligrafie-js": () => import("./../../../src/pages/kalligrafie.js" /* webpackChunkName: "component---src-pages-kalligrafie-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-templates-album-js": () => import("./../../../src/templates/album.js" /* webpackChunkName: "component---src-templates-album-js" */),
  "component---src-templates-bilder-js": () => import("./../../../src/templates/bilder.js" /* webpackChunkName: "component---src-templates-bilder-js" */)
}

